<template>
  <div class="grid justify-center auto-cols-max grid-flow-col gap-4">
    <!-- Questionnaire -->
    <div class="_indicator-category">
      <div v-if="show_title">
        <label class="font-bold">
          Avaliação
        </label>
      </div>
      <div class="gap-4">
        <!-- Scheduled -->
        <div class="_indicator-block">
          <span class="font-bold">
            {{ scheduledCount }}
          </span>
          <label>
            Prevista
          </label>
        </div>
        <!-- In progress -->
        <div class="_indicator-block">
          <span class="font-bold">
            {{ inProgressCount }}
          </span>
          <label>
            Não Finalizada
          </label>
        </div>
        <!-- Finished -->
        <div class="_indicator-block">
          <span class="font-bold">
            {{ finishedCount }}
          </span>
          <label>
            Finalizada
          </label>
        </div>
      </div>
    </div>

    <!-- Events -->
    <div class="_indicator-category" v-if="show_events">
      <div v-if="show_title">
        <label class="font-bold">
          Eventos
        </label>
      </div>
      <div>
        <!-- Total Quantity -->
        <div class="_indicator-block">
          <span class="font-bold">
            {{ eventsCount }}
          </span>
          <label>
            Total
          </label>
        </div>
        <!-- Quantity By Severity -->
        <div class="flex flex-row gap-2">
          <div class="_indicator-block">
            <!-- Low Severity -->
            <span class="font-bold">
              {{ lowSeverityCount }}
            </span>
            <label class="text-success">
              Baixo
            </label>
          </div>
          <div class="_indicator-block">
            <!-- Medium Severity -->
            <span class="font-bold">
              {{ mediumSeverityCount }}
            </span>
            <label class="text-warning">
              Médio
            </label>
          </div>
          <div class="_indicator-block">
            <!-- High Severity -->
            <span class="font-bold">
              {{ highSeverityCount }}
            </span>
            <label class="text-danger">
              Alto
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    indicators: {
      type: Object,
      default: null
    },
    show_events:{
      type: Boolean,
      default: true
    },
    show_title:{
      type: Boolean,
      default: true
    },
  },
  computed: {
    highSeverityCount() {
      return this._.get(this.indicators, 'high_events')
    },
    mediumSeverityCount() {
      return this._.get(this.indicators, 'medium_events')
    },
    lowSeverityCount() {
      return this._.get(this.indicators, 'low_events')
    },
    eventsCount() {
      return this._.get(this.indicators, 'total_events')
    },
    finishedCount() {
      return this._.get(this.indicators, 'finished_answers')
    },
    inProgressCount() {
      return this._.get(this.indicators, 'answers_in_progress')
    },
    scheduledCount() {
      return this._.get(this.indicators, 'scheduled_answer_count')
    },
  }
}
</script>

<style lang="scss" scoped>
  ._indicator-block {
    // The element where the value lies.
    & > span {
      @apply font-bold;
      @apply w-full;
      @apply text-center;
      @apply block;
      @apply text-lg;
    }

    // The element where the value's label is.
    & > label {
      @apply w-full;
      @apply text-center;
      @apply block;
      @apply text-base;
    }
  }

  ._indicator-category {
    // Every element of a category div.
    & > div {
      @apply w-full;
    }

    // Title Block of a Category
    & > :nth-child(1) {
      @apply text-lg;
      @apply flex;
      @apply justify-center;
      @apply pb-4;
    }
    // Container of indicator-blocks on an indicator-category.
    & > :nth-child(2) {
      @apply flex;
      @apply flex-row;
      @apply gap-6;
      @apply justify-center;
    }
  }
</style>>
